import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Playground } from './pages/index';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<Playground />} />
				{/* <Route path="/playground" element={<Playground />} /> */}
				{/* <Route path="/play" element={<Play />} /> */}
				{/* <Route path="/validate" element={<Validate />} />
				<Route path="/intro" element={<Intro />} />
				<Route path="*" element={<NotFoundPage />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
