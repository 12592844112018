import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAdf3AguODka_zAeePsWj68qrErbzxGz3E",
    authDomain: "brandfeels-cf508.firebaseapp.com",
    projectId: "brandfeels-cf508",
    storageBucket: "brandfeels-cf508.appspot.com",
    messagingSenderId: "1098851605068",
    appId: "1:1098851605068:web:a7b629966dff38cea9f2e9",
    measurementId: "G-M6W4TNL9XS"
}

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { firestore, storage };
