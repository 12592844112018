import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection } from "firebase/firestore";
import Logo from "../../content/logos/brandfeels.png";
import Roulette from "../../content/imgs/roulette.png";
import { firestore } from "../firebase"
import "./styles.css";


interface TimeObj {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

function Playground() {
    
    // Variables
    const [date, setDate] = useState<string>("December 31, 2023 00:00:00");
    const [timeObj, setTimeObj] = useState<TimeObj>({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        const roletaGame = collection(firestore, "roleta");
    
        const fetchData = async () => {
            const docRef = doc(roletaGame, "stats");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setDate(docSnap.data().nextGameDate);
            }
        };
    
        fetchData();
    
        let interval: NodeJS.Timeout | null = null;
        const countDownDate = new Date(date).getTime();
    
        const updateTimer = () => {
            const now = new Date().getTime();
            const distance = countDownDate - now;
            const remainingTime = getTimeRemaining(distance);
            setTimeObj(remainingTime);
    
            // Setting interval when there's less than one hour left
            if (remainingTime.hours < 1 && !interval) {
                interval = setInterval(updateTimer, 1000);
            }
        };
    
        // Initial timer update
        updateTimer();
    
        return () => {
            if (interval) {
                clearInterval(interval); // Cleanup on component unmount
            }
        };
    }, [date]);

    const getTimeRemaining = (distance: number): TimeObj => {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        return { days, hours, minutes, seconds };
    };

    const displayTime = (time: TimeObj) => {
        let labelOne, labelTwo, labelThree, labelFour;

        let labelCounter1, labelCounter2;
    
        if (timeObj.days >= 1) {
            labelCounter1 = timeObj.days === 1 ? 'DAY' : 'DAYS';
            labelCounter2 = timeObj.hours === 1 ? 'HOUR' : 'HOURS';
        } else if (timeObj.hours < 1) {
            labelCounter1 = timeObj.minutes === 1 ? 'MIN' : 'MINS';
            labelCounter2 = timeObj.seconds === 1 ? 'SEC' : 'SECS';
        } else {
            labelCounter1 = timeObj.hours === 1 ? 'HOUR' : 'HOURS';
            labelCounter2 = timeObj.minutes === 1 ? 'MIN' : 'MINS';
        }

        if (timeObj.days >= 1) {
            labelOne = Math.floor(timeObj.days / 10);
            labelTwo = timeObj.days % 10;
            labelThree = Math.floor(timeObj.hours / 10);
            labelFour = timeObj.hours % 10;
        } else if (timeObj.hours < 1) {
            labelOne = Math.floor(timeObj.minutes / 10);
            labelTwo = timeObj.minutes % 10;
            labelThree = Math.floor(timeObj.seconds / 10);
            labelFour = timeObj.seconds % 10;
        } else {
            labelOne = Math.floor(timeObj.hours / 10);
            labelTwo = timeObj.hours % 10;
            labelThree = Math.floor(timeObj.minutes / 10);
            labelFour = timeObj.minutes % 10;
        }



        if (timeObj.days <= 0 && timeObj.hours <= 0 && timeObj.minutes <= 0 && timeObj.seconds <= 0) {
            return (
                <>
                    <div className="timer-card" >0</div>
                    <div className="timer-card label one" data-label={labelCounter1}>0</div>
                    <div id="timer-dots">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="timer-card" >0</div>
                    <div className="timer-card label two" data-label={labelCounter2}>0</div>
                </>
            );
        }

        return (
            <>
                <div className="timer-card" >{labelOne}</div>
                <div className="timer-card label one" data-label={labelCounter1}>{labelTwo}</div>
                <div id="timer-dots">
                    <div></div>
                    <div></div>
                </div>
                <div className="timer-card" >{labelThree}</div>
                <div className="timer-card label two" data-label={labelCounter2}>{labelFour}</div>
            </>
        );
    };

    return <div className="relative ">
        <header className="bg-[var(--bg)] relative z-10 ">
            <nav>
                <div className="flex p-12 pl-[8vw]" onClick={() => window.location.replace('https://brandfeels.com')}>
                    <img src={Logo} alt="logo" className="w-40 cursor-pointer" />
                </div>
            </nav>
        </header>

        <main  className="relative w-full h-screen flex flex-col justify-center items-center text-center -mt-28 ">
            <article>
                <h1 id="main-title" className="w-full text-[var(--purple)] text-[3rem] tracking-widest mb-24">Playground</h1>
                <h2 id="main-title2" className="w-full text-white text-[2.5rem] tracking-[10px] mb-28">
                    One <span className="border-b-4 border-[var(--purple)] pb-1">emotion</span> at a <span className="border-b-4 border-[var(--purple)] pb-1">time</span>
                </h2>
                <button id="main-btn" onClick={() => window.location.replace('https://play.brandfeels.com')} className="px-9 py-2.5 bg-[var(--purple)] text-[var(--bg-color)] rounded-full cursor-pointer transition-all ease-in duration-200 hover:bg-[var(--bg-color)] hover:text-[var(--purple)]">Play now</button>
            </article>
        </main>

        <section>
            <article className="relative w-full h-screen flex flex-col justify-center items-center">
                <div className="flex flex-col items-center mb-12">
                    <h1 id='section-h1' className="text-white text-[2rem]">Wait for the next one</h1>
                    <div className="w-4/5 h-1.5 mt-4 bg-[var(--purple)]"></div>
                </div>
                <img id='roulette' src={Roulette} alt="roulette" className="mb-12" />
                <h2 className="text-white text-left">
                    Spin to <span className="border-b-4 border-[var(--purple)]">win rewards</span>
                </h2>
            </article>
        </section>

        <section>
            <article id='timer-container' className="relative w-full h-screen flex flex-col justify-center items-center">
                <h3 className="w-3/5 text-white text-left pl-20 text-[2rem]">Next game</h3>
                <div id="timer">
                    {displayTime(timeObj)}
                </div>
            </article>
        </section>

        <footer id="footer" className="w-full text-sm flex justify-between items-center my-12">
            <p className="text-white px-20">
                Support: <a href="mailto:geral@visiond.pt" className="text-white hover:underline">geral@visiond.pt</a>
            </p>
            <p className="text-white px-20">
                Powered by <span><a href="https://visiond.pt" className="text-[var(--purple)] hover:underline">Vision D</a></span>
            </p>
        </footer>
    </div>
}

export default Playground;